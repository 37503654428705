import Nav from "./nav";
import Footer from "./Footer";
import dynamic from "next/dynamic";

const PostHogPageView = dynamic(() => import("./PostHogPageView"), {
  ssr: false,
});

export default function Layout({ children }) {
  return (
    <div className="px-12">
      <Nav />
      <main>{children}</main>
      {/* <Footer /> */}
      <PostHogPageView />
    </div>
  );
}
