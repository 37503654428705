import { useEffect, useState } from "react";
import Button from "./Button";

export default function Banner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hostname === "dressage.beorn.re") {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, []);

  return (
    <>
      <div
        className="fixed left-0 right-0 top-0 flex items-center justify-between bg-gray-700/60 p-2 text-center text-white drop-shadow backdrop-blur"
        style={{ display: showBanner ? "" : "none" }}
      >
        <div className="p-4">
          Hello dear user, please use the new address{" "}
          <a
            className="underline decoration-green-800 decoration-2 underline-offset-2 hover:text-gray-300"
            href="https://dressage.beorn.re"
          >
            dressage.beorn.re
          </a>{" "}
          to access the app.
        </div>
        <div className="aspect-square p-4">
          <Button
            additionalStyles="bg-gray-900 hover:bg-gray-800 text-white aspect-square"
            onClick={() => setShowBanner(false)}
          >
            X
          </Button>
        </div>
      </div>
    </>
  );
}
