import "@/styles/globals.css";
import Layout from "@/components/layout";
import Head from "next/head";
import { WASMContextProvider } from "@/providers/WASM";
import Banner from "@/components/Banner";
import { PHProvider } from "@/providers/PHProvider";

export default function App({ Component, pageProps }) {
  return (
    <>
      <PHProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="manifest" href="/manifest.json" />
          <meta name="theme-color" content="#006900" />
          <link rel="apple-touch-icon" href="/pwa_96x96.png" />
          <meta name="apple-mobile-web-app-status-bar" content="#006900" />
        </Head>
        <Banner />
        <Layout>
          <WASMContextProvider>
            <Component {...pageProps} />
          </WASMContextProvider>
        </Layout>
      </PHProvider>
    </>
  );
}
