"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

if (typeof window !== "undefined") {
  posthog.init("phc_6JkqB2N1KaEjCcIodrFur3XrjMXOjCkG4ytzMosCr36", {
    api_host: "https://eu.i.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

export function PHProvider({ children }) {
  return <PostHogProvider instance={posthog}>{children}</PostHogProvider>;
}
