import ActiveLink from "@/components/ActiveLink";

export default function Nav() {
  return (
    <nav className="flex flex-row justify-evenly py-8">
      <ActiveLink
        activeClassName="underline decoration-green-900 decoration-4 underline-offset-4"
        className=""
        href="/"
      >
        Generator
      </ActiveLink>
      <ActiveLink
        activeClassName="underline decoration-green-900 decoration-4 underline-offset-4"
        className=""
        href="/mirror"
      >
        Mirror
      </ActiveLink>
      <ActiveLink
        activeClassName="underline decoration-green-900 decoration-4 underline-offset-4"
        className=""
        href="/converter"
      >
        Converter
      </ActiveLink>
      <ActiveLink
        activeClassName="underline decoration-green-900 decoration-4 underline-offset-4"
        className=""
        href="/royale"
      >
        Royale
      </ActiveLink>
      <ActiveLink
        activeClassName="underline decoration-green-900 decoration-4 underline-offset-4"
        className=""
        href="/silent"
      >
        Silent Dressage
      </ActiveLink>
    </nav>
  );
}
