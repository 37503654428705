import { useEffect, useRef } from "react";

export const useMountEffectOnce = (fn) => {
  const wasExecutedRef = useRef(false);
  useEffect(() => {
    if (!wasExecutedRef.current) {
      fn();
    }
    wasExecutedRef.current = true;
  }, [fn]);
};
