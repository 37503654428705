import styles from "@/styles/components/Button.module.css";
import cn from "@/utils/cn";

export default function Button({ children, additionalStyles, ...props }) {
  return (
    <>
      <button
        className={cn(
          "m-[0.2rem] inline-block cursor-pointer rounded bg-green-800 p-[0.5rem] text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50",
          additionalStyles,
        )}
        {...props}
      >
        {children}
      </button>
    </>
  );
}
