import { useState, createContext } from "react";
import { useMountEffectOnce } from "../hooks/useMountEffectOnce";

const initial = {};

export const WASMContext = createContext(initial);

export const WASMContextProvider = ({ children }) => {
  const [state, setState] = useState(initial);

  // This has to run only once: https://github.com/rustwasm/wasm-bindgen/issues/3153
  // Though, in development React renders twice when Strict Mode is enabled: https://reactjs.org/docs/strict-mode.html
  // That's why it must be limited to a single mount run
  useMountEffectOnce(() => {
    (async () => {
      const wasm = await import("flower_wasm");
      await wasm.default();
      setState({ wasm });
    })();
  });

  return <WASMContext.Provider value={state}>{children}</WASMContext.Provider>;
};
