import Link from "next/link";

export default function Footer() {
  return (
    <footer className="absolute bottom-0 left-0 right-0 bg-gray-800 p-4 text-center text-white">
      <p>
        &copy; {new Date().getFullYear()} Aida Beorn –{" "}
        <Link href="/privacy-policy">Privacy Policy</Link>
      </p>
    </footer>
  );
}
