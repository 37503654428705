import { useRouter } from "next/router";
import Link from "next/link";
import { useState, useEffect } from "react";

export default function ActiveLink({
  children,
  activeClassName,
  className,
  ...props
}) {
  const { asPath, isReady } = useRouter();
  const [computedClassName, setComputedClassName] = useState(className);

  useEffect(() => {
    if (isReady) {
      const linkPathName = new URL(props.as || props.href, location.href)
        .pathname;

      const activePathName = new URL(asPath, location.href).pathname;

      const newClassName =
        linkPathName === activePathName
          ? `${activeClassName} ${className}`.trim()
          : className;

      if (newClassName !== computedClassName) {
        setComputedClassName(newClassName);
      }
    }
  }, [
    asPath,
    isReady,
    props.as,
    props.href,
    activeClassName,
    className,
    computedClassName,
  ]);

  return (
    <Link className={computedClassName} {...props}>
      {children}
    </Link>
  );
}
